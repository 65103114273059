// from modules/sliders/slider-page-designer/style.scss
@use 'sass:math';
@import './../../base-bootstrap';

$minheight: 800px;

.slider-page-designer {
    $this: &;

    position: relative;

    .swiper-wrapper {
        height: auto;
        box-sizing: border-box;
    }

    .swiper-slide {
        &:last-child {
            position: relative;

            &:after {
                position: absolute;
                right: -3rem;
                content: '';

                display: block;

                width: 3rem;
                height: 1px;

                @include mq('medium') {
                    right: -5rem;

                    width: 5rem;
                }

                @include mq('large') {
                    right: -7rem;

                    width: 7rem;
                }

                body:not(.is-user-touch) & {
                    display: none;
                }
            }
        }
    }

    .slider-page-designer__header {
        display: none;
    }

    &:hover {
        .swiper-button-prev,
        .swiper-button-next {
            &:not(.swiper-button-disabled) {
                opacity: 1;
            }
        }
    }

    &__container {
        position: relative;

        padding: 4rem var(--bs-gutter-x);

        @include mq('medium') {
            padding-right: calc(var(--bs-gutter-x) * 4);
            padding-left: calc(var(--bs-gutter-x) * 4);
        }

        @include mq('large') {
            padding-right: calc(var(--bs-gutter-x) * 5);
            padding-left: calc(var(--bs-gutter-x) * 5);
        }

        .swiper {
            overflow: visible;

            &:not(.swiper-initialized) {
                .swiper-wrapper {
                    width: unset;
                    margin-right: calc(var(--bs-gutter-x) * -0.5);
                    margin-left: calc(var(--bs-gutter-x) * -0.5);
                    box-sizing: border-box;
                }
            }

            &.swiper-initialized {
                .swiper-slide {
                    flex: auto;
                    flex-grow: 0;
                    flex-shrink: 0;

                    max-width: none;
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            top: 50%;
            right: 0;

            width: auto;
            height: auto;
            margin-top: 0;

            background-image: none;

            transform: translateY(-50%);

            transition:
                opacity 0.4s $ease-out-soft,
                transform $button-out-time $ease-hover;

            @include mq('medium') {
                transform: translateY(-50%);
            }

            &:hover {
                transform: translateY(-50%) scale(1.05);

                @include mq('medium') {
                    transform: translateY(-50%) scale(1.05);
                }
            }

            figure {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 3.2rem;
                height: 3.2rem;

                border-radius: 50%;

                @include mq('medium') {
                    width: 6rem;
                    height: 6rem;
                }

                &,
                section .themed.is-light & {
                    @include DropShadow(4);
                    background-color: $white;
                }

                .is-dark &,
                section .themed.is-dark & {
                    @include DropShadow(4, $grey);
                    background-color: $black;
                }
            }

            svg {
                @include mq('medium', 'max') {
                    width: $spacer;
                    height: ($spacer * 1.33);
                }

                &,
                section .themed.is-light & {
                    fill: $black;
                }

                .is-dark &,
                section .themed.is-dark & {
                    fill: $white;
                }
            }
        }

        .swiper-button-prev {
            left: 0;
            right: auto;

            svg {
                transform: rotate(180deg);
            }
        }

        .swiper-button-disabled {
            opacity: 0;
        }
    }

    &__header {
        $this: &;
        position: relative;

        display: block;

        height: 3.2rem;
        margin: 0 2rem 7.6rem;

        overflow: visible;

        @include mq('medium') {
            height: 4.4rem;
            margin: 0 10.7rem 6rem;
        }

        &.is-after-slider {
            margin-top: 0;
        }
    }

    &[data-number-of-slides='1'] {
        .card--router .card__image {
            @include mq('large') {
                padding-top: min(632px, math.percentage(math.div(632, 1280)));
            }
        }
    }
}

// touch device styles only

body.is-user-touch {
    .slider-page-designer__container {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }
}
